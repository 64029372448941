@import 'fonts.css';

div#root {
  height: 100%;
  background-color: #ABD2FA;
}

body, html {
    height: 100%;
    margin: 0;
}

.font {
  color: white;
  font-family: 'Inter', sans-serif; }

h1 {
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 4rem; }

h2 {
  color: #d0d4e3;
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
}

p {
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem; }

.backcolor {
  background-color: #ABD2FA;
}

ul {
  padding-left: 50px;
}

li {
  color: #d0d4e3;
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
}

.main-cont {
  background-color: #ABD2FA;
  scroll-snap-type: y mandatory; }

.title {
  color: white;
  border-radius: 8px;
  padding: 20px 0px;
  background-color: #091540; 
}

.res-box {
  padding: 20px;
  background-color: #3D518C;
  border-radius: 8px;
}

.questionbox {
  padding: 20px;
  border-radius: 6px;
}

.d2 {
  background-color: #3D518C;
  border-radius: 8px;
  padding: 20px;
  scroll-snap-align: start; }

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: black;
  background: #ABD2FA;
  overflow-x: hidden; }

.label {
  font-size: 1.2rem; }

.lpad {
  padding-left: 20px;
}

.rpad {
  padding-right: 20px;
}

.sb-center {
  align-self: center;
}

.hidden {
  visibility: hidden;
}

input[type="radio"]:checked + label {
  padding-left: 1em;
  background-color: #98f1d2;
  color: #091540; }

input[type="checkbox"]:checked + label {
  padding-left: 1em;
  background-color: #98f1d2;
  color: #091540; 
}

label {
  position: relative;
  display: flex;
  align-items: center;
  grid-template-columns: 20px auto 100px;
  grid-gap: 20px;
  width: 320px;
  padding: 20px 20px;
  border-radius: 8px;
  border: 0px solid transparent;
  background-color: transparent;
  /* transition: all 20ms;  */
}
  label:hover {
    background-color: rgba(4, 32, 85, 0.4);
    color: white; }

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  min-height: 200px; }

  .img {
    max-width: 100%;
    object-fit: contain;
  }